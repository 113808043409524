<template>
  <div>
    <RegisterLessons
      class="rounded-t-0"
      tile
      flat
      :person="$_profilePerson"
      :startDate="startDate"
      :endDate="endDate"
      :showAbsences="false"
      :showEvents="false"
    />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { addDays, formatDayOfWeek, today } from "common/utils/date";

import RegisterLessons from "common/components/Register/RegisterLessons.vue";

export default defineComponent({
  components: {
    RegisterLessons,
  },
  data() {
    return { startDate: null, endDate: null };
  },
  methods: {
    addDays,
    fetchData() {
      let findStart = today();
      let findEnd = addDays(today(), 1);
      if (formatDayOfWeek(findStart) == "Sa") {
        findEnd = addDays(today(), 2);
      }
      if (formatDayOfWeek(findStart) == "So") {
        findStart = addDays(today(), 1);
        findEnd = addDays(today(), 2);
      }

      this.startDate = findStart;
      this.endDate = findEnd;
    },
  },
  created() {
    this.fetchData();
  },
});
</script>
